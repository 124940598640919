import React from 'react';
import { graphql } from 'gatsby';
import StandardLayout from '../layouts/standard-layout';

import styled from 'styled-components';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import { device } from '../utils/breakpoints';
import SEO from '../components/SEO';
import MaxWidthWrapper from '../components/MaxWidthWrapper';

const MainWrapper = styled.div`
  max-width: 640px;
  margin: auto;
  z-index: 1;
  margin-bottom: 40px;
`;

const ArticleHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Page = styled.div`
  margin-top: 80px;
`;

const ArticleTitle = styled.h1`
  font-size: 28px;

  @media ${device.small} {
    font-size: 36px;
  }
`;

const PageTemplate = ({ data }) => {
  const page = data.page;
  return (
    <StandardLayout>
      <SEO
        title={`${page.frontmatter.title} - George Nance`}
        description={page.frontmatter.description}
        article={true}
        image={
          page.frontmatter.coverImage
            ? page.frontmatter.coverImage.publicURL
            : ''
        }
      ></SEO>
      <MaxWidthWrapper>
        <Page className="h-entry">
          <ArticleHead className="article-head">
            <ArticleTitle className="p-name">
              {page.frontmatter.title}
            </ArticleTitle>
          </ArticleHead>
          <MainWrapper>
            <section className="markdown e-content">
              <MDXRenderer>{page.body}</MDXRenderer>
            </section>
          </MainWrapper>
        </Page>
      </MaxWidthWrapper>
    </StandardLayout>
  );
};
export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    page: mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      body
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
