import React from 'react';

import Header from '../components/Header/header';

import Footer from '../components/Footer/Footer';
import styled from 'styled-components';

export default ({ children, data }) => {
  return (
    <>
      <Header></Header>

      {children}
      <Footer></Footer>
    </>
  );
};
